import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import whatsappIcon from "../assets/icons/whatsapp.svg";
import { toWidget } from "@ckeditor/ckeditor5-widget/src/utils";

export default class WhatsAppCTA extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add("whatsappcta", (locale) => {
      const view = new ButtonView(locale);
      view.set({
        label: "Bloque de WhatsApp",
        icon: whatsappIcon,
        tooltip: true,
      });

      view.on("execute", () => {
        this.openWhatsAppDialog();
      });

      return view;
    });

    editor.model.schema.register("whatsappCta", {
      isObject: true,
      isBlock: true,
      allowWhere: "$block",
      allowContentOf: "$block",
      allowAttributes: ["title", "description", "textButton", "url"],
    });

    // Register div.raw-html-embed as a raw content element so all of it's content will be provided
    // as a view element's custom property while data upcasting.
    editor.data.registerRawContentMatcher({
      name: "section",
      classes: "section-whatsapp-cta",
    });

    // <whatsappCta> converters ((data) view → model)
    editor.conversion.for("upcast").elementToElement({
      view: {
        name: "section",
        classes: "section-whatsapp-cta",
      },
      model: (viewElement, { writer: modelWriter }) => {
        const title = viewElement.getAttribute("data-title");
        const description = viewElement.getAttribute("data-description");
        const textButton =
          viewElement.getAttribute("data-textbutton") || "Enviar";
        const whatsappUrl = viewElement.getAttribute("data-url") || "#";

        return modelWriter.createElement("whatsappCta", {
          title,
          description,
          textButton,
          url: whatsappUrl,
        });
      },
    });

    // <whatsappCta> converters (model → data view)
    editor.conversion.for("dataDowncast").elementToElement({
      model: "whatsappCta",
      view: (modelElement, { writer: viewWriter }) => {
        const title = modelElement.getAttribute("title");
        const description = modelElement.getAttribute("description");
        const textButton = modelElement.getAttribute("textButton");
        const whatsappUrl = modelElement.getAttribute("url");

        const container = viewWriter.createContainerElement("section", {
          class: "section-whatsapp-cta",
          "data-title": modelElement.getAttribute("title"),
          "data-description": modelElement.getAttribute("description"),
          "data-textbutton": modelElement.getAttribute("textButton"),
          "data-url": modelElement.getAttribute("url"),
        });
        const textContainerElement = viewWriter.createContainerElement("div", {
          style:
            "display: flex; flex-direction: column; align-items: start; gap: 6px;",
        });
        const titleElement = viewWriter.createContainerElement("h2", {
          style: "color: black; margin: 0;",
        });
        const textNode = viewWriter.createText(title);
        const messageElement = viewWriter.createContainerElement("span", {
          style: "color: black;",
        });
        const messageNode = viewWriter.createText(description);

        viewWriter.insert(
          viewWriter.createPositionAt(titleElement, 0),
          textNode
        );
        viewWriter.insert(
          viewWriter.createPositionAt(textContainerElement, 0),
          titleElement
        );
        viewWriter.insert(
          viewWriter.createPositionAt(messageElement, 0),
          messageNode
        );
        viewWriter.insert(
          viewWriter.createPositionAt(textContainerElement, "end"),
          messageElement
        );
        viewWriter.insert(
          viewWriter.createPositionAt(container, 0),
          textContainerElement
        );

        const linkElement = viewWriter.createContainerElement("a", {
          href: whatsappUrl,
          target: "_blank",
          ariaLabel: "Ir a WhatsApp",
          style:
            "text-decoration: none;border-style: none;color: white;display: flex;text-transform: uppercase;border-radius: 25px;height: 48px;width: fit-content;padding: 12px 18px;font-weight: 700;font-size: 16px;line-height: 22px;text-align: center;outline: none;border: none;cursor: pointer;align-items: center;background-color: #25d366;",
        });

        const buttonElement = viewWriter.createContainerElement("span", {
          style: "color: white;",
        });

        const iconElement = viewWriter.createRawElement(
          "span",
          null,
          (domElement) => {
            domElement.innerHTML = whatsappIcon;
            domElement.style.marginRight = "8px";
            domElement.style.width = "20px";
            domElement.style.height = "20px";
            domElement.style.filter = "invert(1)";
          }
        );

        viewWriter.insert(
          viewWriter.createPositionAt(buttonElement, "end"),
          viewWriter.createText(textButton)
        );
        viewWriter.insert(
          viewWriter.createPositionAt(linkElement, 0),
          iconElement
        );
        viewWriter.insert(
          viewWriter.createPositionAt(linkElement, "end"),
          buttonElement
        );

        viewWriter.insert(
          viewWriter.createPositionAt(container, "end"),
          linkElement
        );

        return container;
      },
    });

    // <whatsappCta> converters (model → editing view)
    editor.conversion.for("editingDowncast").elementToElement({
      model: "whatsappCta",
      view: (modelElement, { writer: viewWriter }) => {
        const title = modelElement.getAttribute("title");
        const description = modelElement.getAttribute("description");
        const textButton = modelElement.getAttribute("textButton");
        const whatsappUrl = modelElement.getAttribute("url");

        const container = viewWriter.createContainerElement("section", {
          class: "section-whatsapp-cta",
          "data-title": modelElement.getAttribute("title"),
          "data-description": modelElement.getAttribute("description"),
          "data-textbutton": modelElement.getAttribute("textButton"),
          "data-url": modelElement.getAttribute("url"),
          style:
            "display: grid; grid-template-columns: 2fr 1fr; gap: 10px;  align-items: center; padding: 24px; border: 1px solid #ddd; border-radius: 5px; background-color: #f8f8f8; text-align: center; margin: 20px 0;",
        });
        const textContainerElement = viewWriter.createContainerElement("div", {
          style:
            "display: flex; flex-direction: column; align-items: start; gap: 6px;",
        });
        const titleElement = viewWriter.createContainerElement("h2", {
          style: "color: black; margin: 0;",
        });
        const textNode = viewWriter.createText(title);
        const messageElement = viewWriter.createContainerElement("span", {
          style: "color: black;",
        });
        const messageNode = viewWriter.createText(description);

        viewWriter.insert(
          viewWriter.createPositionAt(titleElement, 0),
          textNode
        );
        viewWriter.insert(
          viewWriter.createPositionAt(textContainerElement, 0),
          titleElement
        );
        viewWriter.insert(
          viewWriter.createPositionAt(messageElement, 0),
          messageNode
        );
        viewWriter.insert(
          viewWriter.createPositionAt(textContainerElement, "end"),
          messageElement
        );
        viewWriter.insert(
          viewWriter.createPositionAt(container, 0),
          textContainerElement
        );

        const linkElement = viewWriter.createContainerElement("a", {
          href: whatsappUrl,
          target: "_blank",
          ariaLabel: "Ir a WhatsApp",
          style:
            "text-decoration: none;border-style: none;color: white;display: flex;text-transform: uppercase;border-radius: 25px;height: 48px;width: fit-content;padding: 12px 18px;font-weight: 700;font-size: 16px;line-height: 22px;text-align: center;outline: none;border: none;cursor: pointer;align-items: center;background-color: #25d366;",
        });

        const buttonElement = viewWriter.createContainerElement("span", {
          style: "color: white;",
        });

        const iconElement = viewWriter.createRawElement(
          "span",
          null,
          (domElement) => {
            domElement.innerHTML = whatsappIcon;
            domElement.style.marginRight = "8px";
            domElement.style.width = "20px";
            domElement.style.height = "20px";
            domElement.style.filter = "invert(1)";
          }
        );

        viewWriter.insert(
          viewWriter.createPositionAt(buttonElement, "end"),
          viewWriter.createText(textButton)
        );
        viewWriter.insert(
          viewWriter.createPositionAt(linkElement, 0),
          iconElement
        );
        viewWriter.insert(
          viewWriter.createPositionAt(linkElement, "end"),
          buttonElement
        );

        viewWriter.insert(
          viewWriter.createPositionAt(container, "end"),
          linkElement
        );
        return toWidget(container, viewWriter);
      },
    });
  }

  openWhatsAppDialog() {
    const editor = this.editor;

    const createInputField = (placeholder) => {
      const input = document.createElement("input");
      input.placeholder = placeholder;
      input.style.width = "290px";
      input.style.height = "20px";
      input.style.outline = "none";
      input.style.border = "none";
      input.style.borderBottom = "1px solid #878787";
      input.style.backgroundColor = "#E8E8E8";
      input.style.marginBottom = "10px";
      input.style.borderRadius = "4px 4px 0 0";
      input.style.padding = "10px 18px";
      return input;
    };

    const createButton = (text, styles) => {
      const button = document.createElement("button");
      button.innerText = text;
      Object.assign(button.style, styles);
      return button;
    };

    const modalBackground = document.createElement("div");
    Object.assign(modalBackground.style, {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    });

    const modalContainer = document.createElement("div");
    Object.assign(modalContainer.style, {
      padding: "24px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      width: "550px",
      height: "425px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
    });

    const titleField = createInputField(
      "Título (Ej.: ¡Nos interesa tu opinión!)"
    );
    titleField.required = true;
    const descriptionField = createInputField(
      "Detalle (Ej.: ¿Qué te generan los dichos de...?)"
    );
    const messageDefaultField = createInputField(
      "Texto predefinido a recibir (Ej.: Pienso que...)"
    );
    messageDefaultField.required = true;
    const textButtonField = createInputField("Texto del botón (Ej.: ¡Enviar!)");
    const phoneNumberField = createInputField(
      "Número de teléfono receptor (sin el +)"
    );
    phoneNumberField.required = true;
    phoneNumberField.type = "number";

    const buttonsContainer = document.createElement("div");
    Object.assign(buttonsContainer.style, {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      marginTop: "10px",
    });

    const insertButton = createButton("Insertar", {
      backgroundColor: "#3F51B5",
      color: "white",
      border: "none",
      padding: "8px 16px",
      borderRadius: "4px",
      cursor: "pointer",
      textTransform: "uppercase",
    });
    const closeButton = createButton("Cancelar", {
      backgroundColor: "#fff",
      color: "#3F51B5",
      border: "1px solid #3F51B5",
      padding: "8px 16px",
      borderRadius: "4px",
      cursor: "pointer",
      textTransform: "uppercase",
    });
    insertButton.onclick = () => {
      const title = titleField.value.trim();
      const description = descriptionField.value.trim();
      const messageDefault = messageDefaultField.value.trim();
      const textButton = textButtonField.value.trim() || "Enviar";
      const phoneNumber = phoneNumberField.value.trim();

      if (title && phoneNumber && messageDefault && textButton) {
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
          messageDefault
        )}`;

        editor.model.change((writer) => {
          const whatsappCtaElement = writer.createElement("whatsappCta", {
            title,
            description,
            textButton,
            url: whatsappUrl,
          });
          editor.model.insertContent(
            whatsappCtaElement,
            editor.model.document.selection
          );
        });
        document.body.removeChild(modalBackground);
      }
    };

    closeButton.onclick = () => {
      document.body.removeChild(modalBackground);
    };

    modalContainer.append(
      titleField,
      descriptionField,
      messageDefaultField,
      textButtonField,
      phoneNumberField
    );
    buttonsContainer.append(insertButton, closeButton);
    modalContainer.append(buttonsContainer);
    modalBackground.appendChild(modalContainer);
    document.body.appendChild(modalBackground);
  }
}
