import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import '../assets/css/style.css';
import ImageFullBig from '../assets/icons/fullscreen-big.svg';
import ImageFullCancel from '../assets/icons/fullscreen-cancel.svg';

export default class FullScreen extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('fullScreen', locale => {
            const view = new ButtonView(locale);
            let state = 0; //si 0 position normale
            view.set({
                label: 'Pantalla completa',
                icon: ImageFullBig,
                tooltip: true
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {
                if (state === 1) {
                    editor.sourceElement.nextElementSibling.removeAttribute('id');
                    document.body.removeAttribute('id');
                    view.set({
                        label: 'Pantalla completa',
                        icon: ImageFullBig,
                        tooltip: true
                    });
                    if (document.fullscreenElement) {
                        document.exitFullscreen();
                    }
                    state = 0;
                } else {
                    editor.sourceElement.nextElementSibling.setAttribute('id', 'fullscreeneditor');
                    document.body.setAttribute('id', 'fullscreenoverlay');
                    view.set({
                        label: 'Salir de pantalla completa',
                        icon: ImageFullCancel,
                        tooltip: true
                    });
                    if (document.fullscreenEnabled) {
                        document.documentElement.requestFullscreen();
                    }
                    state = 1;
                }

            });

            return view;
        });
    }
}
