import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import SuggestedPickCommand from './SuggestedPickCommand';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import suggestedIcon from '../../assets/icons/bulb.svg';

export default class SuggestedPickEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        this._defineSchema();
        this._defineConverters();

        // Lógica para insertar la lista
        editor.commands.add('insertNote', new SuggestedPickCommand(this.editor));

        const handleNoteSelectedSuggested = (event) => {
            const { editorId, notes, title } = event.detail
            if (editorId === editor.id) {
                const ids = notes.map(item => item.id);

                editor.execute('insertNote', ids, title);
                editor.editing.view.focus();

                window.removeEventListener('selected-notes-suggested', handleNoteSelectedSuggested)
            }
        }

        const customEvent = new CustomEvent('open-multinoticia-picker', {
            detail: {
                editor: editor.id
            },
            bubbles: false,
            cancelable: true,
            composed: false,
        });

        // Boton
        editor.ui.componentFactory.add('insertSuggested', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Puede Interesarte',
                icon: suggestedIcon,
                tooltip: false
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {
                window.addEventListener('selected-notes-suggested', handleNoteSelectedSuggested);
                // Disparar event.
                window.dispatchEvent(customEvent);
            });

            return view;
        });
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('notePreview', {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,

            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',
            allowAttributes: ['id','title']
        });
    }

    _defineConverters() {
        const editor = this.editor;
        const conversion = editor.conversion;
        const renderNote = editor.config.get('notes').noteRenderer;

        // <notePreview> converters ((data) view → model)
        conversion.for('upcast').elementToElement({
            view: {
                name: 'section',
                classes: 'noteSuggested'
            },
            model: (viewElement, { writer: modelWriter }) => {
                // Read the "data-id" attribute from the view and set it as the "id" in the model.
                return modelWriter.createElement('notePreview', {
                    title: viewElement.getAttribute('data-title'),
                    id: viewElement.getAttribute('data-id').split(','),
                });
            }
        });

        // <notePreview> converters (model → data view)
        conversion.for('dataDowncast').elementToElement({
            model: 'notePreview',
            view: (modelElement, { writer: viewWriter }) => {
                // In the data view, the model <notePreview> corresponds to:
                //
                // <section class="note" data-id="..."></section>

                return viewWriter.createEmptyElement('section', {
                    class: 'noteSuggested',
                    'data-id': modelElement.getAttribute('id'),
                    'data-title': modelElement.getAttribute('title'),
                });
            }
        });

        // <notePreview> converters (model → editing view)
        conversion.for('editingDowncast').elementToElement({
            model: 'notePreview',
            view: (modelElement, { writer: viewWriter }) => {
                // In the editing view, the model <notePreview> corresponds to:
                //
                // <section class="note" data-id="...">
                //     <div class="note__react-wrapper">
                //         <notePreview /> (React component)
                //     </div>
                // </section>
                const id = modelElement.getAttribute('id');
                const title = modelElement.getAttribute('title');

                // The outermost <section class="note" data-id="..."></section> element.
                const section = viewWriter.createContainerElement('section', {
                    class: 'noteSuggested',
                    'data-id': id,
                    'data-title': title,
                });

                // The inner <div class="note__react-wrapper"></div> element.
                // This element will host a React <notePreview /> component.
                const reactWrapper = viewWriter.createRawElement('div', {
                    class: 'note__react-wrapper'
                }, function (domElement) {
                    // This the place where React renders the actual note preview hosted
                    // by a UIElement in the view. You are using a function (renderer) passed as
                    // editor.config.notes#noteRenderer.
                    renderNote(id, title,domElement);
                });

                viewWriter.insert(viewWriter.createPositionAt(section, 2), reactWrapper);

                return toWidget(section, viewWriter, { label: 'note preview widget' });
            }
        });
    }
}