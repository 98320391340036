import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

export default class MediaLibrary extends Plugin {
    init() {
        const editor = this.editor;
        const customEvent = new CustomEvent('open-imagepicker', {
            detail: {
                editor: editor.id
            },
            bubbles: false,
            cancelable: true,
            composed: false,
        });

        const handleImageSelected = (event) => {
            const { editorId, image } = event.detail
            if (editorId === editor.id) {
                editor.model.change(writer => {
                    const imageElement = writer.createElement('imageBlock', {
                        src: image.imageUrl,
                        alt: image.alt_text
                    });
                    
                    if (image.caption) {
                        writer.appendElement('caption', imageElement);
                        writer.appendText(image.caption, imageElement.getChild(0));
                        editor.execute('toggleImageCaption', { focusCaptionOnShow: true });
                    } else {
                        editor.execute('toggleImageCaption', { focusCaptionOnShow: false });
                    }
                    // Insert the image in the current selection location.
                    editor.model.insertContent(imageElement, editor.model.document.selection);
                });
                window.removeEventListener('selected-image-imagepicker', handleImageSelected)
            }
        }

        editor.ui.componentFactory.add('mediaLibrary', locale => {
            const view = new ButtonView(locale);

            view.set({
                label: 'Agregar imagen',
                icon: imageIcon,
                tooltip: true
            });

            // Callback executed once the image is clicked.
            view.on('execute', () => {
                window.addEventListener('selected-image-imagepicker', handleImageSelected);
                // Disparar event.
                window.dispatchEvent(customEvent);
            });

            return view;
        });
    }
}