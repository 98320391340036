import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertSuggestedPickCommand extends Command {
    execute(id, title) {

        this.editor.model.change(writer => {
            this.editor.model.insertContent(writer.createElement('notePreview', { id, title }));
        });
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'notePreview');

        this.isEnabled = allowedIn !== null;
    }
}